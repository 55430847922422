<template>
    <div class="aboutContainer">
        <div class="container">

            <h1>託運條款</h1>

            <h2>第一條：未便承運之貨物</h2>

            <ul>
                <li>1. 未依貨件性質、重量等狀況做合適保護包裝之貨件。</li>
                <li>2. 1個大盒子可裝載25公斤（K801箱子尺寸：69 x 48 x 38 cm；容量：85L）。</li>
                <li>3. 超過150c.c.的機車。</li>
                <li>4. 除機車外，大型物件單邊長度過200公分，或總重過40KG重，不予以配送。</li>
                <li>5. 液化物品使用瓶裝、罐裝，包裝不夠堅固，不堪震盪、容易破漏者。輸送時間無法配合本公司者。</li>
                <li>6. 動物活、死體、疫苗、血液、檢體及冷藏物品。</li>
                <li>7. 冰棒、冰淇淋、奶油裝飾蛋糕、母奶。</li>
                <li>8. 仿冒盜版品、違禁品、危險物品(具強烈腐蝕性、爆炸性之物品、有毒化學藥品)、對人體或其他託運品有生命損害/危險可能之物品。</li>
                <li>9. 其他經運送人認定無法承運之貨品。</li>
            </ul>

            <h2>第二條：本公司不負賠償責任狀況、託運人連帶責任</h2>

            <ul>
                <li>1. 第一條「未便承運之貨物」所致之損害。</li>
                <li>2. 本網站為第三方平台提供『共享物流』之服務，僅負責消費者與司機間的媒合，建議消費者事先和司機約定好時段取貨，若有發生任何取/收貨時間上的誤差，本公司恕無法提供賠償。。</li>
                <li>3. 因「未便承運之貨物」造成其他人託運人之損害，託運人須負連帶賠償責任。</li>
                <li>4. 天災或不可抗力之情事所致之損害。</li>
                <li>5. 不可歸咎於本公司之火災、水災等災害</li>
                <li>6. 因貨物性質造成之故障、腐壞、生銹、爆炸、起火等事由。</li>
                <li>7. 因社會運動事件、罷工、刑事案件造成之損害。</li>
                <li>8. 因法律或公權力執行所致之查封、沒收、拆封、停止運送等作為造成損害。</li>
                <li>9. 未規定者依相關法令或習慣處理之。</li>
                <li>10. 託運資料填寫錯誤。</li>
                <li>※「損害」指毀損、遺失。</li>
            </ul>

            <h2>第三條：運費加成及報值</h2>

            <ul>
                <li>1. 配送偏遠地區、配送過程繁瑣困難之貨件。</li>
                <li>2. 保值貨物應保險並加收300元保險費。經保險同意且已收取保險費之貨物，每件以新台幣三萬元為賠償金額上限；故貨物價值超過上述金額者，貨件應予分包託運。</li>
            </ul>

            <h2>第四條：貨故理賠</h2>

            <ul>
                <li>1. 運送物本體以外之其他利益或所失利益不予賠償。</li>
                <li>2. 未報值者如發生貨故時，賠償金額每件以不超過新台幣3,000元整為限。</li>
                <li>3. 機車因運送過程受傷，例如：後照鏡破裂、輪胎少一顆…等，重大損害者予以賠償。</li>
            </ul>

            <h2>第五條：為確保貨物安全及配送效能，請注意下列相關事項</h2>

            <ul>
                <li>1. 下單24小時內須匯款完成才會進行排單，以匯款時間作為排單根據。若超過時間匯款則須重新排單。</li>
                <li>2. 下單時間與取貨時間之間隔不得小於三天。</li>
                <li>3. 請用保護完善包裝材料(如氣泡袋、泡棉、舊報紙、保麗龍、海綿)予以保護貨物，包裝建議如下。</li>
                <li>4. 玻璃/陶瓷品：每件物品請分開包裝保護，如茶壺與壺蓋請分開。</li>
                <li>5. 電器/儀器：以原廠出貨包材包裝，如原廠包材已丟棄，須以替代包材或填充泡棉、舊報紙等以減少產品晃動。</li>

                <li>6. 電腦主機及螢幕： 以原廠出貨包材包裝，如原廠包材已丟棄，須以替代包材或填充泡棉、舊報紙等以減少產品晃動。若需裸裝配送者，運送之損害自行吸收。</li>
                <li>7. 瓜類蔬果：瓜果類以舊報紙填充內箱，可避免因晃動而裂開。</li>
                <li>8. 小型配件/小型物件：小型產品建議放入小紙箱，並以緩衝包材填充，以避免因產品體積小不明顯而被忽略。</li>
                <li>9. 服飾類：若使用破壞袋包裝，請於內層加放氣泡袋等，以避免因勾破而使物品掉落；為避免破壞袋外拖運單脫落應使用膠帶再次黏貼。</li>
                <li>10. 產品裝箱請勿高過外箱高度，以免封箱時外箱高凸不平整，外箱請以專用封箱膠帶黏貼，可視情形將外箱以打包帶捆綁。</li>
                <li>11. 裝箱時間為15分鐘（以司機交予客戶箱子後開始計算），每超過15分鐘需負擔200元的拖延費給司機。</li>
                <li>12. 交貨、收貨時間前一個小時司機會撥打電話提醒消費者，依照原定時間10分鐘後都未曾聯絡到人，將視為已收貨，不得要求退費。</li>
                <li>13. 寄貨日期兩個禮拜前取消訂單者，全額退費；寄貨日期前一個禮拜取消訂單者，退訂單金額之50%；寄貨日期三天前要求取消訂單者，不予退費，以上手續費均由消費者自行負擔。</li>
                <li>14. BIGBOX擁有審核訂單是否成立的權利，若因偏遠地區導致訂單取消之消費者不須負擔手續費。</li>
                <li>15. 若有需要幫忙搬運，司機搭電梯上樓至門口幫忙搬運+100元，若需爬樓梯一層樓+200元。</li>
                <li>16. 收貨完成後，箱子須歸還給司機</li>
                <li>17. 假日不休息；遇國定假日則休息，恕不提供服務。</li>
                <li>17. BIGBOX擁有最終決定權。</li>
            </ul>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Terms"
    }
</script>

<style scoped>

</style>